<template>
    <div>
        <v-row style="font-size: 12px;">
            <v-col cols="11" sm="6">
                (সার্কুলার নং ২৬/২০১৬ এর ০৪(ক) অনুচ্ছেদ দ্রষ্টব্য)
            </v-col>
            <v-col cols="11" sm="5" style="text-align: right;">
                (ফরম - খ)
            </v-col>
        </v-row>
        <div style="margin-top: 5px; text-align: center;">
            রপ্তানি উন্নয়ন ব্যুরো কর্তৃক প্রদত্ত সনদপত্র<br />
            পাটকাঠি থেকে উতপাদিত কার্বন রপ্তানি খাতে রপ্তানিকারকদের ভর্তুকি প্রাপ্তির সনদপত্র।
        </div>
        <v-row style="margin-top: 3px;">
            <v-col cols="11" sm="4">
                ১। আবেদনকারী প্রতিষ্ঠানের নামঃ
                <div style="margin-left: 10px;">ঠিকানাঃ</div>
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.User.OrganizationName }}
                <div>{{ formData.SvForm.OfficeAddress }}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ২। রপ্তানি ঋণপত্র/রপ্তানি চুক্তিপত্রের নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.LcNo }}
                <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.LcDate, '', 'DD/MM/YYYY') }}
                <span style="margin-left: 5px;"></span>মূল্যমানঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.LcCurrencyId) }} {{ formData.SvForm.LcValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৩। বিদেশি ক্রেতার নামঃ
                <div style="margin-left: 10px;">ঠিকানাঃ</div>
            </v-col>
            <v-col cols="11" sm="7" v-if="formData.SvForm.Importer">
                {{formData.SvForm.Importer.Name}}
                <div>{{formData.SvForm.DestinationAddress}}, {{formData.SvForm.DestinationCity}}, {{formData.SvForm.DestinationZipCode}}, {{ $globalHelpers.getCountryNameById(formData.SvForm.DestinationCountryId)}}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৪। বিদেশি ক্রেতার ব্যাংকের নামঃ
                <div style="margin-left: 10px;">ঠিকানাঃ</div>
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.ImporterBankName }}
                <div>{{ formData.ImporterBankAddress }}</div>
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৫। ক) ইনভয়েস নম্বরঃ
                <div style="margin-left: 10px;">খ) ইনভয়েসে উল্লেখিত পণ্যের পরিমাণঃ</div>
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.SvForm.InvoiceNo }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.InvoiceDate, '', 'DD/MM/YYYY') }}
                <div>
                    <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                    <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.InvoiceCurrencyId) }} {{ formData.SvForm.InvoiceValue }}</div>
            </v-col>
        </v-row>
        <div style="margin-top: 5px;">
            ৬। ক) রপ্তানি পণ্যে ব্যবহৃত স্থানীয় উপকরণাদির বর্ণনাঃ
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">সরবরাহকারীর নাম ও ঠিকানা</th>
                        <th class="text-left">উপকরণের নাম ও পরিমাণ</th>
                        <th class="text-right">মূল্য</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(material, i) in formData.IncentiveFormLocalMaterials" :key="i">
                        <td>{{ material.SupplierName }}<br />{{ material.SupplierAddress }}</td>
                        <td>{{ material.MAterial }}<br />{{ material.Qty }} {{ $globalHelpers.getUnitTypName(material.UnitTypeId) }}</td>
                        <td class="text-right">{{ $globalHelpers.getCurrencyName(material.CurrencyId) }} {{ material.Price }}</td>
                    </tr>
                    <tr>
                        <th class="text-right">Total</th>
                        <th class="text-left">
                            <span v-for="(quantity, i) in materialTotals.local.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                        </th>
                        <th class="text-right">
                            <span v-for="(price, i) in materialTotals.local.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                        </th>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <div class="mt-2 ml-4" v-if="formData.IncentiveFormImportedMaterials.length > 0">খ) রপ্তানি পণ্যে ব্যাবহৃত আমদানিকৃত উপকরণাদির বর্ণনাঃ</div>
            <v-simple-table dense v-if="formData.IncentiveFormImportedMaterials.length > 0">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">সরবরাহকারীর নাম ও ঠিকানা</th>
                        <th class="text-left">ঊপকরণের নাম ও পরিমাণ</th>
                        <th class="text-right">মূল্য</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(material, i) in formData.IncentiveFormImportedMaterials" :key="i">
                        <td>{{ material.SupplierName }}<br />{{ material.SupplierAddress }}</td>
                        <td>{{ material.MAterial }}<br />{{ material.Qty }} {{ $globalHelpers.getUnitTypName(material.UnitTypeId) }}</td>
                        <td class="text-right">{{ $globalHelpers.getCurrencyName(material.CurrencyId) }} {{ material.Price }}</td>
                    </tr>
                    <tr>
                        <th class="text-right">Total</th>
                        <th class="text-left">
                            <span v-for="(quantity, i) in materialTotals.imported.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                        </th>
                        <th class="text-right">
                            <span v-for="(price, i) in materialTotals.imported.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                        </th>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div style="margin-top: 5px;">
            <div v-if="formData.IncentiveTypeId !== 3">
                ৭। ক) রপ্তানি পণ্যের বিবরণঃ
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">পণ্যের বিবরণ</th>
                            <th class="text-left">পরিমাণ</th>
                            <th class="text-right">মূল্য</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(hsCode, i) in formData.SvForm.SvFormHSCodes" :key="i">
                            <td>{{ hsCode.HSCode.Code }}: {{ hsCode.HSCode.Description}}</td>
                            <td>{{ hsCode.Qty }} {{ $globalHelpers.getUnitTypName(hsCode.UnitTypeId) }}</td>
                            <td class="text-right">{{ $globalHelpers.getCurrencyName(hsCode.CurrencyId) }} {{ hsCode.Price }}</td>
                        </tr>
                        <tr>
                            <th class="text-right">Total</th>
                            <th class="text-left">
                                <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                            </th>
                            <th class="text-right">
                                <span v-for="(price, i) in materialTotals.hscode.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span>
                            </th>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>

            <v-row style="margin-top: 1px;">
                <v-col cols="11" sm="4">
                    <div style="margin-left: 10px;">
                        খ )প্রকৃত রপ্তানিকৃত পণ্যের পরিমাণঃ
                        <div style="margin-left: 10px;">মূল্যঃ</div>
                    </div>
                </v-col>
                <v-col cols="11" sm="7">
                    <span v-for="(quantity, i) in materialTotals.hscode.quantities" :key="i"><span v-if="i !== 0">,</span> {{quantity.total}} {{ $globalHelpers.getUnitTypName(quantity.unitTypeId) }}</span>
                    <div><span v-for="(price, i) in materialTotals.hscode.prices" :key="i"><span v-if="i !== 0">,</span> {{price.total}} {{ $globalHelpers.getCurrencyName(price.currencyId) }}</span></div>
                </v-col>
            </v-row>
        </div>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৮। জাহাজীকরণের তারিখঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getFormattedDate(formData.SvForm.BLDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>গন্তব্য বন্দর {{ formData.SvForm.DestinationPort }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ৯। ইএক্সপি নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getFormattedEXPNo(formData.SvForm.EXPNo)  }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.SvForm.EXPDate, '', 'DD/MM/YYYY') }} <span style="margin-left: 5px;"></span>মূল্যঃ {{ $globalHelpers.getCurrencyName(formData.SvForm.ExpCurrencyId) }} {{ formData.SvForm.ExpValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১০। মোট প্রত্যাবাসিত রপ্তানি মূল্য (বৈদেশিক মূদ্রায়):
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getCurrencyName(formData.RepatriatedValueCurrencyId) }} {{ formData.RepatriatedValue }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১১। প্রত্যাবাসিত রপ্তানি মূল্যের সনদপত্র নম্বরঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ formData.PRCId }} <span style="margin-left: 5px;"></span>তারিখঃ {{ $globalHelpers.getFormattedDate(formData.PRCDate, '', 'DD/MM/YYYY') }}
            </v-col>
        </v-row>
        <v-row style="margin-top: 0;">
            <v-col cols="11" sm="4">
                ১২। নীট এফওবি মূল্যঃ
            </v-col>
            <v-col cols="11" sm="7">
                {{ $globalHelpers.getCurrencyName(formData.RealisedNetFOBValueCurrencyId) }} {{ formData.RealisedNetFOBValue }}
            </v-col>
        </v-row>
        <div class="incentive-footer-declaration">
            <div style="margin-top: 20px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
            <div style="margin-top: 10px;">
                এতদ্বারা প্রত্যয়ন করা যাচ্ছে যে, আমাদের নিজস্ব কারখানায় স্থানীয়ভাবে সংগ্রহকৃত পাটকাঠি উপরোক্ত ৬নং ক্রমিকে বর্ণিত সূত্র হতে সংগ্রহের মাধ্যমে রপ্তানির বিপরীতে ভর্তুকির জন্য উপরোক্ত অনুচ্ছেদগুলোতে উল্লিখিত বক্তব্য সঠিক ও নির্ভূল। বিদেশী ক্রেতা/আমদানিকারকের ক্রয়াদেশের যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত করা হলো।
            </div>
            <div style="margin-top: 50px; text-align: right;">(রপ্তানিকারকের স্বাক্ষর ও তারিখ)</div>
            <div style="margin-top: 10px;">
                রপ্তানিকারকের উপরোক্ত ঘোষণার যথার্থতা যাচাইয়ান্তে সঠিক পাওয়া গিয়েছে। ৭নং ক্রমিকে উল্লিখিত ঘোষিত রপ্তানিমূল্য যৌক্তিক ও বিদ্যমান আন্তর্জাতিক বাজার মূল্যের সংগে সংগতিপূর্ণ পাওয়া গিয়েছে এবং বিদেশী ক্রেতার যথার্থতা/বিশ্বাসযোগ্যতা সম্পর্কেও নিশ্চিত হওয়া গিয়েছে। প্রত্যাবাসিত রপ্তানি মূলোর (নীট এফওবি মূল্য) ওপর রপ্তানি ভর্তুকি পরিশোধের সুপারিশ করা হলো।
            </div>
            <div style="margin-top: 40px; text-align: right;">রপ্তানি উন্নয়ন ব্যুরোর দুইজন কর্মকর্তার স্বাক্ষর ও তারিখ</div>
        </div>

        <div class="incentive-footer-warning">
            কোন প্রকার ঘষামাজা, কাটাছেড়া বা সংশোধন করা হলে এই প্রত্যয়নপত্র বাতিল বলে গণ্য হবে।
        </div>
    </div>
</template>

<script>
export default {
    name: "IncentiveForm1",
    data: function() {
        return {}
    },
    props: {
        formData: {
            type: Object,
            default: ()=> null
        },
        materialTotals: {
            type: Object,
            default: ()=> null
        },
    },
    methods: {

    },
}
</script>

<style scoped>

</style>